import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Particles
import Particles from 'react-particles-js';

//Importing Modal
import ModalSection from '../../components/common/ModalSection';

class Section extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false
        }
        this.callModal.bind(this)
    }

    callModal = () => {
        this.refs.child.openModal();
    }
    
    render() {
        return (
            <React.Fragment>
                <section className="section bg-home height-100vh" id="home">
                    <div className="bg-overlay">
                            <Particles
                                params={{
                                    "particles": {
                                        "number": {
                                            "value": 150
                                        },
                                        "size": {
                                            "value": 5
                                        }
                                    },
                                    "interactivity": {
                                        "events": {
                                            "onhover": {
                                                "enable": true,
                                                "mode": "repulse"
                                            }
                                        }
                                    },
                                    "move" : {
                                        "radius" : 1
                                    }
                                }}
                            />
                    </div>
                    <div className="">
                        <div className="">
                            <Container className="slidero">
                                <Row>
                                    <Col lg={{size:8, offset:2}} className="text-white text-center">
                                        <img src="assets/images/logo.svg" />
                                        <h4 className="home-small-title">GDPR encryption platform</h4>
                                        <h1 className="home-title">An easy and compliant GDPR solution for system developers</h1>
                                        <p className="padding-t-15 home-desc mx-auto">Qriptiqa enables developers to capture, hold and retain GDPR related information without risking compliance issues or security.</p>
                                     
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Section;