import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Input } from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
     constructor(props) {
        super(props);
        this.state = {
            footerItems : [
                { title : "QRIPTIQA" ,
                    links : [
                        { linkTitle : "Home", link : "#" },
                        { linkTitle : "About us", link : "#" },
                        { linkTitle : "Features", link : "#" },
                        { linkTitle : "Pricing", link : "#" },
                    ]
                },
                { title : "Developers" ,
                    links : [
                        { linkTitle : "Developer support", link : "#" },
                        { linkTitle : "API", link : "#" },
                        { linkTitle : "Service status", link : "#" },
                    ]
                },
                { title : "" ,
                    links : [
                        { linkTitle : "<image src=\"assets/images/expersoft.png\" class=se />", link : "https://www.expersoft.com/" },  
                        { linkTitle : "<image src=\"assets/images/se.svg\" class=se />", link : "https://www.stelle.admin.ch/stelle/de/home.html" }
                    ]
                },
            ],
            isSwitchToggle : false
        }
    }

    render() {
        return (
            <React.Fragment>
                <footer className="footer">
                    <Container>
                        <Row>
                            {
                                this.state.footerItems.map((item, i) =>
                                    <Col lg="3" className="margin-t-20" key={"col-link-footer-"+i}>
                                        <h4>{item.title}</h4>
                                        <div className="text-muted margin-t-20">
                                            <ul className="list-unstyled footer-list">
                                                {
                                                    item.links.map((link, i) =>
                                                        <li key={"link-footer-"+i}><Link to={link.link} dangerouslySetInnerHTML={{__html: link.linkTitle}}></Link></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </Col>
                                )
                            }
                            <Col lg="3" className="margin-t-20">
                                <h4>Monthly Newsletter</h4>
                                <div className="text-muted margin-t-20">
                                    <p>Subscribe to keep up to date with the latest GDPR complience tips, best development practises and more</p>
                                </div>
                                <Form className="form subscribe">
                                    <Input placeholder="Email" className="form-control" required/>
                                    <Link to="#" className="submit"><i className="pe-7s-paper-plane"></i></Link>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </footer>
                
                <FooterLinks/>
            </React.Fragment>
        );
    }
}

export default Footer;