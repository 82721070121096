import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import PricingBox from './pricing-box';

class Pricing extends Component {
    state = {
        pricings : [
            { id : 1, title : "Country", price : "139.90€", duration : "/ MONTH",
                features : [
                    {a: 'Compliant policies', b: '1 Country' },
                    {a: 'Encryption requests', b: '200' },
                    {a: 'Hashing requests', b: 'Unlimited' },
                    {a: 'Data retention', b: 'Fixed' },
                    {a: 'Data deletion', b: 'Fixed' },
                    {a: 'Auditing', b: 'Basic' },
                    {a: 'Rate limit', b: '10/m' },
                ]
                
            },
            { id : 2, title : "International", price : "299.90€", duration : "/ MONTH", isRibbon : true,
                features : [
                    {a: 'Compliant policies', b: 'European Union' },
                    {a: 'Encryption requests', b: 'Unlimited' },
                    {a: 'Hashing requests', b: 'Unlimited' },
                    {a: 'Data retention', b: 'Custom' },
                    {a: 'Data deletion', b: 'Custom' },
                    {a: 'Auditing', b: 'Advanced' },
                    {a: 'Rate limit', b: '1/s' },
                    {a: 'Zapier integration', b: 'Included' },
                ]
                
                },
                { id : 3, title : "Governing bodies", price : "ON REQUEST", duration : "SEPA payments available",
                features : [
                    {a: 'Qriptiqa experts are available for collaboration on Government level projects.', b: '' },
                    {a: '', b: 'All from International+' },
                    {a: 'Cloud automation', b: 'Unlimited' },
                    {a: 'Support', b: 'Advanced' },
                    {a: 'On-premise', b: 'Available' },
                    {a: 'Government ISO', b: 'Available' },
                ]
                
            },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="pricing">
                    <Container>
                        {/* Render section title */}
                        <SectionTitle title="Our Pricing" description="Call to action pricing table is really crucial to your for your business website. Make your bids stand-out with amazing options."/>

                        <Row className="margin-t-50">
                        {/* Render Pricing Box */}
                        {
                            this.state.pricings.map((pricing, i) =>
                                <PricingBox pricing={pricing} key={"pricing-item-"+i}/>
                            )
                        }
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Pricing;