import React, { Component } from 'react';
import { Container, Row, Col, NavLink } from "reactstrap";

class WebsiteDescription extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section bg-web-desc">
                    <div className="bg-overlay"></div>
                    <Container>
                        <Row>
                            <Col lg="12" className="text-center containlinks">
                                <h2 className="text-white">Solve storing GDPR data with ease</h2>
                                <p className="padding-t-15 home-desc mx-auto">Qriptiqa enables you and your development team to stay GDPR compliant when storing personal data under the EU law.</p>
                                <NavLink href="#pricing" className="btn btn-bg-white margin-t-30 waves-effect waves-light short" >View Plan & Pricing</NavLink>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default WebsiteDescription;