import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
    state = {
        services : [
            { title : "Patented encryption", icon : "pe-7s-study", description : "Qriptiqa is secured using a military grade proprietary encryption algorithm developed by the mathematicians at Mathematik Universität Zürich." },
            { title : "Power of the cloud", icon : "pe-7s-cloud", description : "Qriptiqa is available to be privately launched instantly within the Public or Government sector using the latest in AWS Elastic Beanstalk." },
            { title : "Keyless by design", icon : "pe-7s-key", description : "Qriptiqa uses a patented Keyless design approach which focuses on the encryption pattern and process to provide unique security for every clients action. No two inputs are remotely the same result." },
            { title : "Audit actions", icon : "pe-7s-lock", description : "Supported by ISO 9180, Qriptiqa logs, actions and history can be audited down to the finest detail." },
            { title : "Perfect Toolbox", icon : "pe-7s-tools", description : "Qriptiqa was developed by developers for developers. Our strong and secure REST API is readily available for immediate consumption and use." },
            { title : "Always Documented", icon : "pe-7s-news-paper", description : "Our engineers strive to constantly maintain the Qriptiqa documentation up to date with the latest features of new, legacy and deprecated features." },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="features">
                    <Container>

                        {/* Render section title */}
                        <SectionTitle title="Our Services" description="We are a group of Swiss ISACA®’s Certified Data Privacy Solutions Engineers™ (CDPSE™) We provide ready made solutions to international clients when it comes to retaining and transferring sensitive information."/>

                        <Row className="margin-t-30">
                            {/* render service box */}
                            {
                                this.state.services.map((service, i) =>
                                    <ServiceBox title={service.title} icon={service.icon} description={service.description} key={"service-id-"+i}/>
                                )
                            }
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Services;