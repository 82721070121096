import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class Features extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section" id="developer-first">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg="5" className="order-2 order-lg-1">
                                <div className="features-box mt-5 mt-lg-0">
                                    <h3>A developer friendly way to protect and comply with GDPR data</h3>
                                    <p className="text-muted web-desc">Create a reversible hash with compliance in mind</p>
                                    <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                                        <li className="">Automatic deletion across all data upon request</li>
                                        <li className="">Protected data storage in your database</li>
                                        <li className="">Time limited, extendable, reverse keys, with user privacy in mind</li>
                                    </ul>
                                    <Link to="/signup" className="btn btn-custom margin-t-30 waves-effect waves-light">Signup now <i className="mdi mdi-arrow-right"></i></Link>
                                </div>
                            </Col>
                            <Col lg={{size: 7, order: 2, }} xs={{order : 1}}>
                                <div className="features-img mx-auto mr-lg-0">
                                    <img src="assets/images/growth-analytics.svg" alt="macbook" className="img-fluid" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Features;