import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Blog Box
import BlogBox from "./blog-box";


class Blog extends Component {
    state = {
        blogs : [
            { id : 1, image : "assets/images/blog/img-1.jpg", topic : "Security", title : "Securing Data in transit", description : "Securing data in transit can be easily supported with any of the Qriptiqa pricing plans.", link : ""},
            { id : 2, image : "assets/images/blog/img-2.jpg", topic : "Automation", title : "Automation and Qriptiqa with Zapier", description : "Qriptiqa has released a widget on the popular automation platform Zapier. Integration available on International plans and beyond.", link : ""},
            { id : 3, image : "assets/images/blog/img-3.jpg", topic : "Compliance", title : "Digesting personal information with AWS RDS & Qriptiqa", description : "Roll your own version of the Qriptiqa platform on the AWS. Now available for GovCloud partners and official integrations.", link : ""},
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="news">
                    <Container>

                        {/* Render section title */}
                        <SectionTitle title="Latest News" description="Read about through carefully curated selection of articles on the topic of GDPR compliance, automation and data security."/>

                        <Row className="margin-t-30">
                            {/* Render blog boxes */}
                            {
                                this.state.blogs.map((blog, i) =>
                                    <BlogBox blog={blog} key={"blog-item-"+i}/>
                                )
                            }
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Blog;