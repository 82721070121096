import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

class PricingBox extends Component {
    render() {
        return (
            <React.Fragment>
                            <Col lg="4">
                                <div className="text-center pricing-box bg-white price-active">
                                    {
                                        this.props.pricing.isRibbon
                                        ? <div className="ribbon-box"><span>Popular</span></div>
                                        : null
                                    }
                                    <h4 className="text-uppercase">{this.props.pricing.title}</h4>
                                    <h1>{this.props.pricing.price}</h1>
                                    <h6 className="text-uppercase text-muted">{this.props.pricing.duration}</h6>
                                    <div className="plan-features margin-t-50">
                                        {
                                            this.props.pricing.features.map((service) =>
                                                <p key={"pricing-col-item-"+Math.random(0,50000)}>{service.a} <b className="text-custom">{service.b}</b></p>
                                            )
                                        }
                                    </div>
                                    { this.props.pricing.title !== 'Governing bodies' ? 
                                    
                                    <Link to="/signup" className="btn btn-custom waves-effect waves-light margin-t-30">Sign up Now</Link>
                                    : ''
                                    }
                                </div>
                            </Col>
            </React.Fragment>
        );
    }
}

export default PricingBox;