import React, { Component } from 'react';
import { Container, Row, Col, NavLink} from "reactstrap";

class GetStarted extends Component {
    render() {
        return (
            <React.Fragment>
        <section className="section section-lg bg-get-start">
            <div className="bg-overlay"></div>
            <Container>
                <Row>
                    <Col lg={{size :8, offset:2}} className="text-center">
                        <h1 className="get-started-title text-white">By developers for Developers</h1>
                        <div className="section-title-border margin-t-20 bg-white"></div>
                        <NavLink href="#contact" className="btn short btn-bg-white waves-effect margin-t-20">Get Started <i className="mdi mdi-arrow-right"></i> </NavLink>
                        <p className="section-subtitle font-secondary text-white text-center padding-t-30">We've built our platform with a developer first approach. Our clean, simple, well-documented and speedy API is all you need! Our multicultural team of engineers has made sure that your can implement Qriptiqa in your project in an instant using our broad variety of Libraries and SDKs.</p>
                    </Col>
                </Row>
            </Container>
        </section>
            </React.Fragment>
        );
    }
}

export default GetStarted;